<template>
  <Sidebar />
  <div class="font-roboto flex flex-col min-h-screen">
    <div class="max-w-7xl mx-auto flex-1 w-full">
      <h1 v-if="!showPopup" class="text-dark-blue text-3xl font-bold text-center mt-8 mb-5">Create a Campaign</h1>
      <div v-if="!showPopup" class="max-w-7xl mx-auto px-4 space-y-10 divide-y divide-gray-900/10 pb-10 font-roboto">
        <!-- Personal Information -->
        <div v-if="!showPopup && !$keycloak.authenticated" class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
          <div class="px-4 sm:px-0">
            <h2 class="text-2xl font-semibold leading-7 text-dark-blue">Personal Information</h2>
          </div>
          <UserForm
            @update="user = $event"
            @has-error="hasUserError = $event"
            :email-disabled="false"
            :user="user"
            :show-errors="showFormErrors">
          </UserForm>
        </div>

        <!-- Campaign Information -->
        <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
          <div class="px-4 sm:px-0">
            <h2 class="text-2xl font-semibold leading-7 text-dark-blue">Campaign Information</h2>
            <div class="text-xl font-semibold leading-7 text-dark-blue"><br>How to work out your fundraising target</div>
            <div class="text-sm">
              <br>GetGo deducts a 7.5% administration cost, as well as transaction costs, from the funds raised for your campaign. This helps us maintain our website and provide services to you and other campaigners. To ensure you reach your desired fundraising target, please increase your target by 7.5%. Additionally, we recommend adding about 2.5% to your campaign financial goal to cover transaction costs. Once your campaign has ended, we deduct the 7.5% administration cost, as well as the accumulated transaction costs, and then pay out the remainder to your school.
              <br><br><a class="font-bold"> Total Fundraising Goal = Fundraising Target + Administration Costs + Transaction Costs</a><br>
              <li>Total Fundraising Goal is the amount needed to be raised in order for the campaigner to receive their fundraising target.</li>
              <li>Fundraising Target is the financial amount that the campaigner needs to raise.<br></li>
              <li>Administration costs are 7.5% that will be deducted from the funds raised.<br></li>
              <li>
                Transaction costs are the accumulated transaction costs of donations received for the campaign. <a class='font-bold'>Please note that transaction costs vary based on the payment method used by a donor. Therefore, the transaction cost of 2.5% is only an estimation and not a true reflection of the transaction costs accumulated for your campaign.</a></li>
                <br class='font-normal'>Please note that you are not guaranteed to receive your full fundraising goal or target. If your campaign ends before your fundraising goal is reached, i.e. the campaign has reached its end date but has not accumulated all funds, GetGo will still deduct 7.5% administration costs as well as the accumulated transaction costs from the amount raised before disbursement is made to the school.
            </div>
          </div>
          <CampaignForm @submit.prevent="hasCampaignError || (keycloak.authenticated ? true : hasUserError)"
              @update="campaign = $event"
              @has-error="hasCampaignError = $event"
              @file-change="setCoverPhoto"
              :schools="schools as SchoolDto[]"
              :campaign="campaign"
              :show-errors="showFormErrors">
            <template v-slot:actions>
              <div class="col-span-full sm:w-96 sm:mx-auto mt-0 sm:mt-4">
                <Button
                    type="submit"
                    @click="handleSubmit"
                    buttonText="Create Campaign"
                    class="bg-light-blue text-white py-2.5 w-full rounded-md"
                />
              </div>
            </template>
          </CampaignForm>
          
        </div>
      </div>

      <!-- Campaign Created Success page-->
      <AddCampaignSuccess 
        :showPopup="showPopup"
        :showSuccessImage="showSuccessImage"
        :popupTitle="popupTitle"
        :popupShortMessage="popupShortMessage"
        :popupLongMessage="popupLongMessage"
      />
    </div>
    <Footer />
  </div>
</template>

<script setup lang="ts">
import Footer from '@/components/Atomic/AQuarks/Footer.vue'
import Sidebar from '@/components/Layouts/Sidebar.vue'
import Button from '@/components/Atomic/AQuarks/Button.vue'
import AddCampaignSuccess from '@/components/Atomic/BAtoms/AddCampaignSuccess.vue'
import {useCampaignStore} from '@/areas/campaigns/stores/campaignStore'
import {useSchoolStore} from '@/areas/schools/stores/schoolStore'
import {CampaignType} from '@/areas/campaigns/enums/campaignType'
import {AssetType} from '@/areas/campaigns/enums/assetType'
import {computed, onMounted, PropType, ref} from 'vue'
import keycloak from '@/keycloak'
import {CampaignDto} from "@/areas/campaigns/dtos/campaignDto";
import {UserDto} from "@/areas/users/dtos/userDto";
import {CampaignAssetDto} from "@/areas/campaigns/dtos/campaignAssetDto";
import {CreateCampaignDto} from "@/areas/campaigns/dtos/createCampaignDto";
import UserForm from "@/areas/users/containers/UserForm.vue";
import CampaignForm from "@/areas/campaigns/containers/CampaignForm.vue";
import {SchoolDto} from "@/areas/schools/dtos/schoolDto";
import {SchoolStateEnum} from "@/areas/schools/enums/schoolStateEnum";

const props = defineProps({
  user: {
    type: Object as PropType<UserDto>,
    default: {
      firstName: '',
      lastName: '',
      email: '',
    }
  },
  campaign: {
    type: Object as PropType<CampaignDto>,
    default: {
      name: '',
      school: {},
      targetAmount: undefined,
      campaignType: undefined,
      startDate: undefined,
      endDate: undefined,
      individuals: [],
      story: '',
      campaignAssets: [],
    }
  }
})

const showFormErrors = ref<boolean>(false)
const schoolStore = useSchoolStore()
const schools = ref<SchoolDto[]>()

const campaign = ref(props.campaign)
const user = ref(props.user)
const campaignTypes = Object.values(CampaignType)

const coverPhoto = ref<File>()
const errorMessage = ''
//Booleans
const hasUserError = ref<boolean>(true)
const hasCampaignError = ref<boolean>(true)
const showSuccessPage = ref<boolean>(false)
const consentChecked = ref<boolean>(false)
const privacyChecked = ref<boolean>(false)
const showSuccessImage = ref<boolean>(false)
const showPopup = ref<boolean>(false)
const showCampaignTypeDropdown = ref<boolean>(false)
const showGradeDropdown = ref<boolean>(false)

const popupTitle = ref<string>('')
const popupShortMessage = ref<string>('')
const popupLongMessage = ref<string>('')
const parentsGuardianPhoneNumber = ref<string>('')
const learnerGrade = ref<string>('')

const selectedCampaignType = null
const selectedGrade = null

onMounted(async () => {
  const schoolResult = await schoolStore.fetchAllSchools([SchoolStateEnum.Approved], '')
  
  if (schoolResult.isSuccessful){
    schools.value = schoolResult.content!
  }
  
  if (keycloak.authenticated){
    let loggedInUser = await keycloak.loadUserProfile()
    
    user.value = {
      firstName: loggedInUser.firstName!,
      lastName: loggedInUser.lastName!,
      email: loggedInUser.email!
    } as UserDto
    
    hasUserError.value = false
  }
})

const setShowPopup = (newPopupValue: boolean, newSuccessImageValue: boolean) => {
  showPopup.value = newPopupValue;
  showSuccessImage.value = newSuccessImageValue;
}

const createCampaign = async (campaignDto: CampaignDto) => {
  const campaignStore = useCampaignStore();
  
  let schoolGuid = campaignDto.school.guid
  
  const createCampaignDto = {
    story: campaignDto.story,
    targetAmount: campaignDto.targetAmount,
    startDate: campaignDto.startDate,
    endDate: campaignDto.endDate,
    creator: user.value,
    displayName: campaignDto.displayName,
    campaignType: campaignDto.campaignType,
    individuals: campaignDto.individuals,
  } as CreateCampaignDto
  
  // TODO: This argument for campaign is not correct
  const createCampaignResponse = await campaignStore.createCampaignRequest(schoolGuid, createCampaignDto);
  
  if (!createCampaignResponse.isSuccessful){
    return await setErrorMessage(createCampaignResponse.error!);
  }
  
  let campaignResult = createCampaignResponse.content! as CampaignDto
  
  await uploadCoverPhoto(campaignResult.guid);
}

const setCoverPhoto = async (file: File) => {
  coverPhoto.value = file
}

const uploadCoverPhoto = async (campaignGuid: string) => {
  const campaignStore = useCampaignStore();
  
  if(!coverPhoto.value){
    // TODO: Handle error
    return
  }
  
  const uploadPhotoResult = await campaignStore.uploadCoverPhoto(coverPhoto.value as Blob, campaign.value.school.guid, campaignGuid, {
    assetType: AssetType.CoverPhoto,
    mimeType: coverPhoto.value.type,
  } as CampaignAssetDto);
  
  if (!uploadPhotoResult.isSuccessful){
    // TODO: Handle error
    return
  }
  
  await setSuccessMessage();
  
  return uploadPhotoResult;
}

const setErrorMessage = async (error: string) => {
  setShowPopup(true, false);
  switch (error) {
    case 'DuplicateCampaign':
      popupTitle.value = 'Duplicate Campaign';
      popupShortMessage.value = 'This campaign already exists';
      break;

    case 'SchoolNotFound':
      popupTitle.value = 'School Not Found';
      popupShortMessage.value = 'The school requested does not exist';
      break;

    case 'SchoolIncorrectState':
      popupTitle.value = 'School Incorrect State';
      popupShortMessage.value = 'School is not in the correct state to allow this action';
      break;
    default:
      popupTitle.value = 'Something went wrong';
      popupShortMessage.value = 'The campaign could not be requested';
      break;
  }
}

const setSuccessMessage = async () => {
  setShowPopup(true, true);
  popupTitle.value = 'Campaign Created';
  popupShortMessage.value = `Thank you ${user.value.firstName} ${user.value.lastName} for creating a campaign`;
  popupLongMessage.value = `
          We appreciate that you created a campaign, we’re currently authorising it. 
          So hang tight and we’ll send you confirmation very soon!  
          If any more support is needed you can either email support at 
          <a class="text-light-blue underline" href="mailto:support@getgofund.org">support@getgofund.org</a> 
          or you can view 
          <a class="text-light-blue underline" href="/faq">FAQs</a>.
        `;
}

const handleSubmit = async () => {
  if (!showFormErrors.value) {
    showFormErrors.value = true
  }

  if (hasUserError.value || hasCampaignError.value) {
    return
  }
  
  // TODO: Create request
  createCampaign(campaign.value).then(() => {
    window.scrollTo(0,0)
  })
  
}
</script>
