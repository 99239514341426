import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LandingView from '../views/LandingView.vue'
import DonateNow from '../views/DonateNow.vue'
import AddCampaign from '../views/AddCampaign.vue'
import MyProfile from '../views/MyProfile.vue'
import AboutUs from '../views/AboutUs.vue'
import HowItWorks from '../views/HowItWorks.vue'
import ContactUs from '../views/ContactUs.vue'
import FAQ from '../views/FAQ.vue'
import UserRegistration from '../views/UserRegistration.vue'
import RegisterMySchool from '../views/RegisterMySchool.vue'
import ManageUsers from '../views/ManageUsers.vue'
import ManageSchools from '../views/ManageSchools.vue'
import ManageCampaigns from '../views/ManageCampaigns.vue'
import FindMySchool from '../views/FindMySchool.vue'
import PaymentSuccess from '../views/PaymentSuccess.vue'
import MyDonations from '../views/MyDonations.vue'
import keycloak from '@/keycloak'
import {useUserStore} from "@/areas/users/stores/userStore";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/landing'
  },
  {
    path: '/landing',
    name: 'Landing',
    component: LandingView
  },
  {
    path: '/profile/:year?',
    name: 'MyProfile',
    component: MyProfile,
    props: route => ({user: route.params.user }),
    meta: { roles: ['GetGoAdmin', 'SchoolAdmin', 'User'] }
  },
  {
    path: '/about',
    name: 'AboutUs',
    component: AboutUs,
  },
  {
    path: '/how-it-works',
    name: 'HowItWorks',
    component: HowItWorks
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/donate',
    name: 'DonateNow',
    component: DonateNow,
  },
  {
    // Success For Yoco Payment
    path: '/donate/schools/:schoolGuid/campaigns',
    name: 'Success',
    component: DonateNow,
  },
  {
    // Failure for Yoco Payment
    path: '/donate/schools/:schoolGuid/campaigns/:campaignGuid',
    name: 'Failure',
    component: DonateNow,
  },
  {
    path: '/payment/success',
    name: 'PaymentSuccess',
    component: PaymentSuccess,
  },
  {
    path: '/my-donations/:year?',
    name: 'MyDonations',
    component: MyDonations,
    props: true,
    meta: { roles: ['GetGoAdmin', 'SchoolAdmin', 'User'] }
  },
  {
    path: '/add-campaign',
    name: 'AddCampaign',
    component: AddCampaign,
  },
  {
    path: '/user-registration',
    name: 'UserRegistration',
    component: UserRegistration,
    meta: { roles: ['GetGoAdmin'] }
  },
  {
    path: '/register/school',
    name: 'Register My School',
    component: RegisterMySchool,
  },
  {
    path: '/manage/users',
    name: 'ManageUsers',
    component: ManageUsers,
    meta: { roles: ['GetGoAdmin'] }
  },
  {
    path: '/manage/schools',
    name: 'ManageSchools',
    component: ManageSchools,
    meta: { roles: ['GetGoAdmin'] }
  },
  {
    path: '/manage/campaigns',
    name: 'ManageCampaigns',
    component: ManageCampaigns,
    meta: { roles: ['SchoolAdmin'] }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

//Check if roles are present before routing to the views
router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore()
  const isAuthenticated = keycloak.authenticated;
  const requiredRoles = to.meta.roles as string[];
  
  window.addEventListener('beforeunload', () => {
    userStore.saveState()
  })
  
  userStore.restoreState();

  if (isAuthenticated !== userStore.isAuthenticated){
    if (!isAuthenticated){
      await userStore.setAuthentication(false)
    }
    if (isAuthenticated){
      await userStore.getOrCreate();
    }
  }

  if (requiredRoles) {
    if (isAuthenticated) {
      const userRoles = (keycloak.tokenParsed as any)?.realm_access?.roles || [];
      const hasRequiredRole = requiredRoles.some((role: string) => userRoles.includes(role));

      if (hasRequiredRole) {
        next();
      } else {
        next('/landing');
      }
    } else {
      next('/landing');
    }
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  if (to.path.startsWith("/donate") && from.path.startsWith("/donate")) {
    window.location.reload();
  }
});

export default router