<template>
  <div class="flex flex-col min-h-screen">
    <Sidebar />
    <div class="bg-white flex-1">
      <div v-if="!schoolStore.school && !campaignStore.campaign" class="mx-auto max-w-7xl py-24 sm:px-6 sm:py-12 lg:px-8">
        <div class="relative isolate px-6 py-12 text-center sm:px-16">
          <h2 v-text="pageTitle" class="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-dark-blue sm:text-4xl"></h2>
          <p v-text="pageDescription" class="mx-auto mt-6 max-w-xl text-lg leading-8 text-dark-blue"></p>
          <div v-if="showSearchInput === 'school'" class="mt-10 flex flex-col items-center">
            <div class="flex flex-col items-start sm:w-96 relative mx-auto">
              <label v-text="labelText" class="text-dark-blue mb-2"></label>
                <SchoolSearch
                  :style="'mt-2 block rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:leading-6'"
                  :school="schoolStore.school === null ? undefined : schoolStore.school"
                  :filtered-schools="filteredSchools"
                  @school-change="selectSchool"
                  @input="(newSchool) => filterSchools(newSchool)"
                />
            </div>
          </div>

          <div v-else-if="showSearchInput === 'campaign'" class="mt-10 flex flex-col items-center">
            <div class="flex flex-col items-start w-96 relative">
              <div class="flex flex-col items-start sm:w-96 relative mx-auto">
                <label v-text="labelText" class="text-dark-blue mb-2"></label>
                <CampaignSearch
                    :style="'mt-2 block rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:leading-6'"
                    :campaign="campaignStore.campaign === null ? undefined : campaignStore.campaign"
                    :filtered-campaigns="filteredCampaigns"
                    @campaign-change="selectCampaign"
                    @input="(newValue) => filterCampaigns(newValue)"
                />
              </div>
            </div>
          </div>

          <div v-else class="mt-10 flex items-center justify-center gap-x-6">
            <Button :buttonText="'Search Campaign'" @click="changePage('campaign')" class="text-white bg-light-blue font-roboto font-normal py-3 w-44 rounded-md shadow-md"/>
            <Button :buttonText="'Search School'" @click="changePage('school')" class="text-dark-blue bg-transparent border border-dark-blue font-roboto font-normal py-3 w-44 rounded-md shadow-md"/>
          </div>
        </div>
      </div>

      <!-- List Of Campaign Cards -->
      <div v-if="schoolStore.school && !campaignStore.campaign && campaignStore.campaigns " class="overflow-hidden">
        <div class="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-12 lg:max-w-7xl lg:px-8">
          <h1 class="text-dark-blue text-3xl text-center font-bold">Select a Campaign</h1>
          <div v-if="campaignStore.campaigns.length > 0" class="mt-12 relative w-96">
            <label v-text="labelText" class="text-dark-blue mb-2"></label>
            <SchoolSearch
                :style="'mt-2 block rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:leading-6'"
                :school="schoolStore.school"
                :filtered-schools="filteredSchools"
                @school-change="selectSchool"
                @input="(newSchool) => filterSchools(newSchool)"
            />
          </div>

          <!-- If no campaigns, display this message -->
          <div v-else class="text-center mt-10">
            <p class="text-4xl text-dark-blue font-bold whitespace-nowrap">No campaigns available yet.</p>
          </div>

          <div class="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8 mt-12">
            <div v-for="campaign in campaignStore.campaigns" :key="campaign.guid" class="group relative flex flex-col overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md hover:scale-105 transition-transform duration-300 ease-in-out">
              <div class="aspect-h-4 aspect-w-3 bg-gray-200 sm:aspect-none sm:h-96">
                <img :src="getCoverPhotoLink(campaign)" alt="Campaign Cover Photo" class="h-full w-full object-cover object-center sm:h-full sm:w-full">
              </div>
              <div @click="selectCampaign(campaign)" class="flex flex-1 flex-col space-y-2 p-4">
                <h3 class="text-2xl mb-2.5 font-bold text-dark-blue">
                  <span class="absolute inset-0"></span>
                  {{ campaign.displayName }}
                </h3>
                <CampaignProgressBar
                    :target-amount="campaign.targetAmount" 
                    :cumulative-donations="campaign.cumulativeDonations"/>
                <div class="flex justify-between">
                  <p class="text-base text-dark-blue mb-3">End Date:</p>
                  <p class="text-base text-orange-red">{{ formatDate(campaign.endDate) }}</p>
                </div>
                <div>
                  <p class="text-dark-blue text-lg font-bold">My Story</p>
                  <p class="text-dark-blue text-sm mt-2">{{ campaign.story }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Selected Campaign Details -->
      <div v-if="campaignStore.campaign !== null && !inCheckout" class="mt-6 mx-8 mb-10">
        <div class="border border-gray-900/5 rounded-md shadow-sm max-w-7xl px-4 sm:px-6 sm:py-12 lg:px-8 mx-auto">
          <div class="flex justify-between">
            <a @click="handleCampaignDetailsBack()" class="flex items-center cursor-pointer">
              <img class="mr-1" src="/img/Icons/Back_arrow.svg" alt="Back">
              <h1 class="text-sm text-dark-blue">Back</h1>
            </a>
          </div>

          <div class="mt-8">
            <div class="space-y-24">
              <div class="grid grid-cols-1 text-sm sm:grid-cols-12 sm:grid-rows-1 sm:gap-x-6 md:gap-x-8 lg:gap-x-8">
                <div class="sm:col-span-4 md:col-span-5 md:row-span-2 md:row-end-2">
                  <div class="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-50">
                    <img :src="getCoverPhotoLink(campaignStore.campaign)" alt="Campaign Cover Photo" class="object-cover object-center w-full">
                  </div>
                </div>
                <div v-if="schoolStore.school" class="mt-6 sm:col-span-7 sm:mt-0 md:row-end-1">
                  <h3 class="text-2xl font-bold text-dark-blue">{{ campaignStore.campaign.displayName }}</h3>
                  <p class="mt-2 font-medium text-gray-500">{{ schoolStore.school!.name }}</p>
                  <p class="mt-1 text-gray-500">{{ schoolStore.school!.address.city }}, {{ schoolStore.school!.address.province}}</p>
                </div>
                <div class="sm:col-span-12 md:col-span-7">
                  <div class="flex my-4">
                    <div>
                      <p class="text-dark-blue text-base">Start Date</p>
                      <p class="text-dark-blue text-sm mt-1.5">{{ formatDate(campaignStore.campaign.startDate) }}</p>
                    </div>
                    <div class="ml-14">
                      <p class="text-dark-blue text-base">End Date</p>
                      <p class="text-dark-blue text-sm mt-1.5">{{ formatDate(campaignStore.campaign.endDate) }}</p>
                    </div>
                  </div>
                  <div class="flex mt-1">
                    <p class="mr-2 text-lg font-medium text-dark-blue">Total Funding Needed:</p>
                    <span class="text-dark-blue text-lg">R{{ campaignStore.campaign !== null ? campaignStore.campaign.targetAmount : '-'}}</span>
                  </div>
                  <CampaignProgressBar
                    :target-amount="campaignStore.campaign.targetAmount" 
                    :cumulative-donations="campaignStore.campaign.cumulativeDonations"/>

                  <div class="h-14 w-full bg-dark-blue rounded-md mt-6 flex items-center">
                    <p class="uppercase text-white text-sm sm:text-lg font-bold pl-2 flex-1">Share Campaign</p>
                    <div class="flex justify-end flex-1 gap-x-2">
                      <a @click="toggleShareModal" class="bg-white mr-2.5 rounded-full text-dark-blue">
                        <img class="h-10 cursor-pointer" src="/img/Icons/Share_Campaign.svg" alt="Share">
                      </a>
                    </div>
                  </div>

                  <div class="h-14 w-full bg-purple rounded-md mt-6 flex items-center">
                    <p class="uppercase text-white text-sm sm:text-lg font-bold pl-2 flex-1">Enter Contribution</p>
                    <div class="flex justify-end flex-1 gap-x-2">
                      <input v-model="donationDto.amount" type="number" placeholder="Enter Amount" class="block w-full rounded-md border-0 py-1.5 px-2 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none mr-2" required/>
                    </div>
                  </div>
                  <span class="text-red-500" v-if="contributionAmountError">{{ contributionAmountError }}</span>
                  <div class="mt-6">
                    <p class="text-lg font-bold text-dark-blue">My Story</p>
                    <p class="mt-1.5">
                      {{ campaignStore.campaign.story }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-end mt-10 pb-4 sm:pb-0">
            <Button @click="handleCheckout" buttonText="Donate" class="bg-light-blue text-white py-2.5 w-72 rounded-md"/>
          </div>
        </div>
      </div>

      <!-- Checkout -->
      <div v-if="inCheckout" class="min-h-screen flex flex-col">
        <h1 class="text-dark-blue text-3xl font-bold text-center mt-8 mb-5">Checkout</h1>
        <div class="max-w-7xl mx-auto px-4 space-y-10 pb-10 font-roboto flex-1 w-full">
          <!-- Personal Information -->
          <div v-if="!$keycloak.authenticated" class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
            <div class="px-4 sm:px-0">
              <h2 class="text-2xl font-semibold leading-7 text-dark-blue">Personal Information</h2>
            </div>
            <NewUserForm
              @update="userDto = $event"
              @has-error="hasUserError = $event"
              :user="userDto!"
              :show-errors="showFormErrors">
            </NewUserForm>
          </div>

          <!-- Summary -->
          <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
            <div class="px-4 sm:px-0">
              <h2 class="text-2xl font-semibold leading-7 text-dark-blue">Summary</h2>
            </div>
            <form @submit.prevent="hasUserError || hasDonationError" class="bg-white shadow-md ring-1 ring-gray-900/5 rounded-xl md:col-span-2">
              <div class="px-4 py-6 sm:p-8">
                <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-auto">
                  <div class="sm:col-span-full">
                    <div class="flex justify-between">
                      <p class="text-dark-blue">Total Donation</p>
                      <p class="text-dark-blue">R {{ formatAmount(effectiveCampaignContribution) }}</p>
                    </div>
                    <div class="flex justify-between mt-3">
                      <div class="text-dark-blue">Admin Cost
                        <tooltip content="The GetGo deducts a 7.5% administration cost from the donation amount. This helps us maintain our platform and provide our services to you and our campaigners."/>
                      </div>
                      <p class="text-dark-blue">R {{ formatAmount(effectiveAdminContribution) }}</p>
                    </div>
                    <hr class="w-full h-0.5 bg-gray-300 my-4">
                    <div class="flex justify-between">
                      <p class="text-dark-blue font-bold text-lg">Total Payment</p>
                      <p class="text-green font-bold text-lg">R {{ formatAmount(donationDto.amount) }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <!-- Terms & Conditions -->
          <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
            <div class="px-4 sm:px-0">
              <h2 class="text-2xl font-semibold leading-7 text-dark-blue">Terms & Conditions of Receipt</h2>
            </div>
            <form class="bg-white shadow-md ring-1 ring-gray-900/5 rounded-xl md:col-span-2 mb-8">
              <div class="px-4 py-6 sm:p-8">
                <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-auto">
                  <div class="sm:col-span-full">
                    <div class="text-dark-blue">This is a bona fide donation? *
                    <tooltip content="A bona fide donation is one that is made in good faith and sincerity. If the recipient of your donation is a relative, connected person, or employee of your company, the donation does not qualify for a Section 18A tax certificate."/>
                  </div>
                  <div class="flex">
                    <div class="flex items-center me-4 mt-4">
                      <input v-model="donationDto.isBonafideDonation" id="inline-radio" type="radio" :value="true" name="inline-radio-group" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:border-gray-600">
                      <label for="inline-radio" class="ms-2 text-sm font-medium text-dark-blue">Yes</label>
                    </div>
                    <div class="flex items-center me-4 mt-4">
                      <input v-model="donationDto.isBonafideDonation" id="inline-2-radio" type="radio" :value="false" name="inline-radio-group" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:border-gray-600">
                      <label for="inline-2-radio" class="ms-2 text-sm font-medium text-dark-blue">No</label>
                    </div>
                  </div>
                  <span v-if="showFormErrors && bonafideError" class="text-red-500 text-sm mt-1" v-text="bonafideError"/>


                  <fieldset @submit.prevent="() => hasDonationError" class="col-span-full mt-8">
                    <div class="space-y-3">
                      <div class="relative flex gap-x-3">
                        <div class="flex h-6 items-center">
                          <input v-model="donationDto.acceptTerms" type="checkbox" class="h-4 w-4 rounded border-gray-300" />
                        </div>
                        <div class="text-sm leading-6">
                          <label class="font-medium text-dark-blue">
                            {{'I accept the GetGo'}}
                            <a href="/docs/Privacy_Policy.pdf" class=" text-light-blue underline">{{'Privacy Policy'}}</a>
                            {{ 'and' }}
                            <a href="/docs/T&C.pdf" class=" text-light-blue underline">{{'Terms and Conditions'}}</a>
                          </label>
                        </div>
                      </div>
                      <span v-if="showFormErrors && termsError" class="text-red-500 text-sm mt-1" v-text="termsError"/>

                      <div class="relative flex gap-x-3">
                        <div class="flex h-6 items-center">
                          <input v-model="donationDto.marketingConsent" type="checkbox" class="h-4 w-4 rounded border-gray-300" />
                        </div>
                        <div class="text-sm leading-6">
                          <label class="font-medium text-dark-blue">
                            {{ 'I agree to receive marketing communications from GetGo.' }}
                          </label>
                        </div>
                      </div>
                      <span v-if="showFormErrors && marketingConsentError" class="text-red-500 text-sm mt-1" v-text="marketingConsentError"/>

                      <div class="relative flex gap-x-3">
                        <div class="flex h-6 items-center">
                          <input v-model="donationDto.isAnonymous" type="checkbox" class="h-4 w-4 rounded border-gray-300" />
                        </div>
                        <div class="text-sm leading-6">
                          <label class="font-medium text-dark-blue">
                            {{ 'Anonymous donation' }}
                          </label>
                        </div>
                      </div>
                      <span v-if="showFormErrors && anonymousError" class="text-red-500 text-sm mt-1" v-text="anonymousError"/>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- Cancel & Donate Buttons -->
        <div class="flex justify-center gap-x-10">
          <Button buttonText="Cancel" @click="cancelCheckout" class="text-dark-blue bg-transparent border border-dark-blue rounded-md py-2 w-72" />
          <Button buttonText="Donate" @click="handleSubmit" class="text-white bg-light-blue rounded-md py-2 w-72" />
        </div>
      </div>
    </div>

    <SocialMediaShare 
      :isVisible="isShareModalVisible" 
      @click:isVisible="isShareModalVisible = $event" 
    />
  </div>
  <Footer />
</div>
</template>

<script setup lang="ts">
import {onBeforeUnmount, onMounted, PropType, ref, watch} from 'vue';
import Footer from '@/components/Atomic/AQuarks/Footer.vue';
import Sidebar from '@/components/Layouts/Sidebar.vue';
import Button from '@/components/Atomic/AQuarks/Button.vue';
import SocialMediaShare from '@/components/Atomic/BAtoms/SocialMediaShare.vue';
import {useCampaignStore} from '@/areas/campaigns/stores/campaignStore'
import {useSchoolStore} from '@/areas/schools/stores/schoolStore'
import {CampaignDto} from '@/areas/campaigns/dtos/campaignDto';
import {SchoolDto} from '@/areas/schools/dtos/schoolDto'
import {AddDonationDto} from "@/areas/sessions/dtos/addDonationDto";
import {CampaignState} from "@/areas/campaigns/enums/campaignState";
import {useRoute} from 'vue-router';
import {SchoolStateEnum} from '@/areas/schools/enums/schoolStateEnum'
import {formatAmount, formatDate} from "@/helpers/utilities/dataFormats";
import CampaignProgressBar from "@/components/Atomic/BAtoms/CampaignProgressBar.vue";
import SchoolSearch from "@/components/Atomic/BAtoms/SchoolSearch.vue";
import {UserDto} from "@/areas/users/dtos/userDto";
import NewUserForm from "@/areas/users/containers/NewUserForm.vue";
import keycloak from "@/keycloak";
import {useSessionStore} from "@/areas/sessions/stores/sessionStore";
import {CreateSessionDto} from "@/areas/sessions/dtos/createSessionDto";
import CampaignSearch from "@/components/Atomic/BAtoms/campaigns/CampaignSearch.vue";
import Tooltip from "@/components/Atomic/AQuarks/Tooltip.vue";

const props = defineProps({
  donationDto: {
    type: Object as PropType<AddDonationDto>,
    default: {
      amount: undefined,
      donationContributionPercentage: 7.5,
      isBonafideDonation: undefined,
      isAnonymous: false,
      marketingConsent: true,
    }
  },
  userDto: {
    type: Object as PropType<UserDto>,
    default: {
      firstName: '',
      lastName: '',
      email: '',
    }
  }
})

// Prop refs
const donationDto = ref(props.donationDto)

const route = useRoute();

// Stores
const schoolStore = useSchoolStore()
const campaignStore = useCampaignStore()
const sessionStore = useSessionStore()

// User
const userDto = ref(props.userDto)

// Schools
const schools = ref<SchoolDto[]>([]);
const filteredSchools = ref<SchoolDto[]>([]);

// Campaigns
const filteredCampaigns = ref<CampaignDto[]>([])

// Donation
const inCheckout = ref<boolean>(false)

// Checkout
const showBonafideTip = ref<boolean>(false)
const showAdminCostTip = ref<boolean>(false)

// Forms
const showFormErrors = ref<boolean>(false)
const hasUserError = ref<boolean>(!keycloak.authenticated)
const hasDonationError = ref<boolean>(true)

const bonafideError = ref<string>()
const termsError = ref<string>()
const marketingConsentError = ref<string>()
const anonymousError = ref<string>()

const isShareModalVisible = ref<boolean>(false);
const pageTitle = ref<string>('Donate');
const pageDescription = ref<string>('You have the option to begin your search by selecting a school first or by directly searching for the campaign.');
const labelText = ref<string>('');
const showSearchInput = ref<string>(''); 
const selectedSchoolInfo = ref<string>('')
const campaignInput = ref<string>('')

const contributionAmountError = ref<string>('');

const effectiveCampaignContribution = ref<number>(0)
const effectiveAdminContribution = ref<number>(0)

watch(() => schoolStore.schools, (newValue) => {
  if (newValue){
    schools.value = newValue
  }
}, { immediate: true })

watch(() => donationDto.value.acceptTerms, (newValue) => {
  if (newValue){
    termsError.value = undefined
  }
})

watch(() => donationDto.value.isBonafideDonation, (newValue) => {
  if (undefined !== newValue){
    bonafideError.value = undefined
  }
})

onBeforeUnmount(() => {
  schoolStore.$reset()
  campaignStore.$reset()
})

onMounted(async () => {
  if (keycloak.authenticated) {
    userDto.value = await keycloak.loadUserProfile() as UserDto
  }
  
  let argSchoolGuid = route.params.schoolGuid
  let argCampaignGuid = route.params.campaignGuid
  
  if (argSchoolGuid){
    await schoolStore.schoolByGuid(argSchoolGuid as string)
    await campaignStore.init(argSchoolGuid as string, CampaignState.Active)
    
    if (argCampaignGuid){
      await campaignStore.fetchCampaign(argSchoolGuid as string, argCampaignGuid as string)
    }
    
    return
  }
      
  await schoolStore.init([SchoolStateEnum.Approved])
})

const handleCheckout = async () => {
  if (!donationDto.value){
    return contributionAmountError.value = 'Contribution amount is required';
  }
  if (!donationDto.value?.amount || donationDto.value.amount <= 0) {
    return contributionAmountError.value = 'Invalid contribution amount';
  }

  window.scrollTo(0, 0)
  
  if (!schoolStore.school || !campaignStore.campaign){
    return
  }
  
  donationDto.value!.schoolGuid = schoolStore.school.guid
  donationDto.value!.campaignGuid = campaignStore.campaign.guid
  
  inCheckout.value = true
  
  effectiveCampaignContribution.value = donationDto.value.amount * ((100-donationDto.value!.donationContributionPercentage)/100)
  effectiveAdminContribution.value = donationDto.value!.amount - effectiveCampaignContribution.value

  localStorage.setItem('postPaymentRedirectPath', `/donate/schools/${donationDto.value!.schoolGuid}/campaigns`);
}

const filterSchools = async (searchTerm: string) => {
  // Reset school select
  if (schoolStore.school){
    //schoolStore.school = null
  }

  if (!schools.value){
    return
  }
  filteredSchools.value = schools.value!.filter(school => school.name?.toLowerCase().includes(searchTerm.toLowerCase())).splice(0, 5)
}

const selectSchool = async (school: SchoolDto) => {
  schoolStore.school = school
  filteredSchools.value = [];
  
  await campaignStore.init(schoolStore.school.guid, CampaignState.Active)
  
  selectedSchoolInfo.value = `${school.name}, ${school.address.city}, ${school.address.province}`;
}

const selectCampaign = async (campaign: CampaignDto) => {
  campaignInput.value = ''
  if (!campaign){
    return
  }
  
  campaignStore.campaign = campaign
  
  if (!schoolStore.school && campaignStore.campaign.school){
    schoolStore.school = campaignStore.campaign.school
  }
  
  if (getCoverPhotoLink(campaign) === '/img/image.svg'){
    await campaignStore.fetchCampaign(campaignStore.campaign.school.guid!, campaignStore.campaign.guid)
  }
  
  window.scrollTo(0, 0)
};

const filterCampaigns = async (searchTerm: string) => {
  if (campaignStore.campaign){
    campaignStore.campaign = null
  }
  
  if (searchTerm.trim() === '') {
    filteredCampaigns.value = campaignStore.campaigns ?? []
    return
  }
  
  let lowerCaseSearchTerm = searchTerm.toLowerCase()
  
  const campaignSearchResult = await campaignStore.fetchCampaignsByName(lowerCaseSearchTerm);
    
  if (campaignSearchResult.isSuccessful){
    filteredCampaigns.value = campaignSearchResult.content! as CampaignDto[];
    return
  }
}

const getCoverPhotoLink = (campaign: CampaignDto) => {
  let coverPhotoAsset = campaign.campaignAssets.find(asset => asset.assetType === 'CoverPhoto');
  
  return coverPhotoAsset?.preSignedLink ? coverPhotoAsset!.preSignedLink : '/img/image.svg';
}

const changePage = (type: string) => {
  if (type === 'school') {
    pageTitle.value = 'Search School';
    labelText.value = 'Search School'
    pageDescription.value = 'You have the option to begin your search by selecting a school.';
    showSearchInput.value = 'school';
  } else {
    pageTitle.value = 'Search Campaign';
    labelText.value = 'Search Campaign'
    pageDescription.value = 'You have the option to begin your search by selecting a campaign.';
    showSearchInput.value = 'campaign';
  }
};

const handleCampaignDetailsBack = async () => {
  if (schoolStore.school){
    const result = await schoolStore.schoolByGuid(schoolStore.school.guid!)
    
    if (result.isSuccessful){
      await selectSchool(result.content! as SchoolDto)
    }
    
  }

  donationDto.value.amount = 0;
  await campaignStore.clearCampaign()
}

// Checkout 
const handleSubmit = async () => {
  showFormErrors.value = true
  
  validateDonation()
  
  // Validate donation
  if (bonafideError.value || marketingConsentError.value || anonymousError.value || termsError.value){
    hasDonationError.value = true
    return
  }
  
  if (hasUserError.value){
    window.scrollTo(0, 0)
    return
  }

  await createPaymentIntent()
}

const createPaymentIntent = async () => {
  const createSessionDto = {
    donation: donationDto.value,
    user: userDto.value
  } as CreateSessionDto

  const result = await sessionStore.createSession(createSessionDto)

  if (!result.isSuccessful){
    // handle error
    return;
  }

  window.location.href = result.content!.payment.redirectUrl
}

const validateDonation = () => {
  bonafideError.value = donationDto.value.isBonafideDonation === undefined ? "Please select whether this is a bona fide donation." : undefined
  termsError.value = donationDto.value.acceptTerms !== true ? "Read and Accept to continue" : undefined
}

const cancelCheckout = () => {
  inCheckout.value = false;
  showCampaignDetails.value = true;
  window.scrollTo(0, 0);
};

// Social Media
const toggleShareModal = () => {
  if (schoolStore.school && campaignStore.campaign) {
    const sharePath = `${process.env.VUE_APP_BASE_APP_URL}/donate/schools/${schoolStore.school.guid}/campaigns/${campaignStore.campaign.guid}`;
    localStorage.setItem('sharePath', sharePath);

    isShareModalVisible.value = !isShareModalVisible.value;
  }
};
</script>